@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import './vars';
@import './mixins';
@import '~bootstrap/scss/bootstrap.scss';

@font-face {
  font-family: ProximaNova;
  src: url("../fonts/proxima-nova.ttf")
}

@font-face {
  font-family: OpenSans;
  src: url("../fonts/open-sans.ttf")
}

* {
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
}

body {
  background: #000;
  height: 100%;
  font-family: OpenSans;
  color: #BBB;
  overflow-y: scroll;
  touch-action: pan-y;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: $blue;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
}

.text-tooltip-error {
  color: $vanilla;
}

.tippy-tooltip {
  padding: 1em 1.5em;
  opacity: 0.9;
}

[disabled] {
  pointer-events: none;
  cursor: default;
  opacity: 0.4 !important;
}

.cats-bg-main {
  @include cats-bg('all', 0.05);

  &.inspire {
    @include cats-bg('inspire', 0.06);
  }

  &.beat {
    @include cats-bg('beat', 0.06);
  }

  &.power {
    @include cats-bg('power', 0.09);
  }
}

.cats-bg {
  @include cats-bg('all');
  background-attachment: unset !important;

  &:hover {
    @include cats-bg('all', 0.8);
  }

  &.inspire {
    @include cats-bg('inspire');

    &:hover {
      @include cats-bg('inspire', 0.9);
    }
  }

  &.beat {
    @include cats-bg('beat');

    &:hover {
      @include cats-bg('beat', 0.9);
    }
  }

  &.power {
    @include cats-bg('power');

    &:hover {
      @include cats-bg('power', 0.9);
    }
  }   

  &[disabled] {
    opacity: 0.2;
  }
}

.card {
  box-shadow: 0px 0px 4px 2px rgba($night, 0.6);
  border: none;
}

.card-title {
  max-width: 100%;
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 0.45em;
}

.card.selectable {
  cursor: pointer;

  &:hover {   
    box-shadow: 0px 0px 4px 2px rgba($gray, 0.6);

    & .card-title {
      color: #FFF;
    }
  }
}

.card.active  {  
  box-shadow: 0px 0px 4px 2px rgba($blue, 0.6) !important;

  &:hover {
    box-shadow: 0px 0px 4px 2px rgba($blue, 0.6) !important;
  }

  & .card-title {
    color: #FFF;
  }
}

.card.inactive {
  opacity: 0.5 !important;
  cursor: default !important;; 
  box-shadow: 0px 0px 4px 2px rgba($dark, 0.6) !important;

  & .card-title {
    color: inherit !important;
  }
}

.card.failed  {
  opacity: 0.5 !important;
  cursor: default !important;; 
  box-shadow: 0px 0px 4px 2px rgba($red, 0.6) !important;

  & .card-title {
    color: inherit !important;
  }
}

.card.unpublished {
  opacity: 0.5 !important;
}

.card-new {
  height: 3.75em;
  display: flex;
  box-shadow: 0px 0px 4px 2px rgba($green, 0.3) !important;
  align-items: center;
  justify-content: center;
  color: $gray;
  cursor: pointer;
  font-weight: bold;

  & > i {
    margin-right: 0.5em;
    font-size: 1.5em;
  }

  & > *:not(i) {
    font-size: 0.9em;
  }

  &:hover {
    color: white;
    box-shadow: 0px 0px 4px 2px rgba($green, 0.5) !important;
  }
}

.card-info {
  flex: 1; 
  min-width: 0;
}

.card-image {
  $width: 3.75em;
  $height: 3.75em;

  position: relative; 
  width: $width; 
  height: $height;
  background: #0F0F0F;

  & > .fa, & > .fas, & > .far, & > .fal {
    font-size: 1.5em;
    opacity: 0.5;
    position: absolute; 
    left: 50%; 
    top: 50%;
    transform: translate(-50%, -50%); 
    z-index: 0;
  }

  & > *:not(.fa):not(.fas):not(.far):not(.fal) {
    width: $width; 
    height: $height; 
    background-size: cover !important;
    background-position: center center !important;
    position: absolute;
    z-index: 1;
  }
}

.alert-not-found {
  background: rgba(darken($night, 5%), 0.7);
  color: darken($gray, 10%);
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.btn-group {
  & > .btn:not(:last-child) {
    border-right: none;
  }
}

.btn-link-tooltip {
  position: relative;

  & tooltip {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.filter-search {
  position: relative;

  & > i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.8em;
    color: $dark;
  }

  & > input {
    padding-left: 2.5em;
    
    &:focus + * {
      display: none;
    }
  }

  &-info {
    position: absolute;
    pointer-events: none;
    color: darken($gray, 10%);
    font-size: 0.8em;
    top: 50%;
    transform: translateY(-50%);
    right: 0.8em;
  }
}

.filter-colors {
  list-style: none;
  display: flex;  
  flex-direction: row;
  justify-content: stretch;
  width: 100%;
  height: 1.4em;

  & > * {
    flex-grow: 1;    
    opacity: 0.2;
    cursor: pointer;

    &.active:hover {
      opacity: 0.55;
    }

    &:hover {
      opacity: 0.35;
    }

    &.active {
      opacity: 0.7;
    }
  }
}

.form-group {
  .custom-checkbox {
    margin-left: 2px;

    & .custom-control-label {
      cursor: pointer;
    }
  }
}