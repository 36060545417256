@import '~styles/vars';

.modal-window-scrollbar.error {
  box-shadow: 0px 0px 4px 2px rgba($red, 0.6);  
}

.modal-window-scrollbar.event {
  box-shadow: 0px 0px 4px 2px rgba($green, 0.6);
}

.modal-window-scrollbar.confirm {
  box-shadow: 0px 0px 4px 2px rgba($orange, 0.8);
}

.preloader {
  position: fixed;
  z-index: 30000;
  width: 100vw;
  height: 100vh;
  background: rgba($night, 0.8);

  &:after {  
    content: '';
    background: url("./img/preloader.svg");  
    background-size: cover;
    width: 5em;
    height: 5em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}