@import '~styles/vars';

.sources {
  .card-title {
    & > i {
      font-size: 0.9em;
      margin-right: 0.3em;
      color: $gray;
    }
  }
}