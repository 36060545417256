@import '~styles/vars';
@import '~bootstrap/scss/bootstrap-grid';

.player {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(darken($night, 5%), 0.9);
  z-index: 100;

  &-progress {
    $min-height: 0.5em;
    position: relative;
    background: rgba($dark, 0.3);
    min-height: $min-height;    
    cursor: pointer;
    font-weight: bold; 

    &-title {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 90%;
    }

    &-mask {
      opacity: 0;
    }
    
    &-actual {
      background: rgba($blue, 0.9);
      min-height: $min-height;
    }

    &-buffer {
      background: rgba($gray, 0.3);
      min-height: $min-height;
    }
  }

  &-title {
    padding: 0 1em 0 4em;
    flex: 1;
    font-size: 1.1em;
    font-weight: bold;
    min-width: 0;
    color: $gray;
  }

  &-buttons {
    height: 2.3em;

    @include media-breakpoint-down(xs) {
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
    }

    &.no-overflow {
      overflow: hidden;
    }

    & > *:not(:last-child) {
      display: inline-block;
      margin-right: 0.6em;
    }

    & tooltip {
      display: inline-block;
    }

    a {
      cursor: pointer;
      position: relative;
      color: $gray;
      font-size: 1.12em;
      background: black;
      width: 1.89em;
      height: 1.89em;
      border-radius: 50%;
      display: inline-block;

      & > i {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;

        &.fa-play {
          left: 53%;
        }

        &.fa-forward {
          left: 53%;
        }

        &.fa-backward {
          left: 47%;
        }
      }

      &:hover {
        color: $blue;
      }

      &.active {
        color: $blue;
      }

      &.active:hover {
        color: $midnight;
      }
    }
  }
}

.player-volume {
  position: relative;
  $size: 0.5em;

  & input[type='range'] { 
    @mixin range-thumb {    
      width: $size;
      height: $size;
      cursor: pointer;
      border-radius: 0;
      border: none;
      opacity: 0;
    }
  
    -webkit-appearance: none; 
    width: 100%;
    height: $size;
    background: rgba($gray, 0.4);
    outline: none;
    cursor: pointer;
  
    &::-ms-track {
      width: 100%;
      cursor: pointer;  
      background: transparent; 
      border-color: transparent;
      color: transparent;
    }
  
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      @include range-thumb;
    }

    &::-moz-range-track {
      height: $size;
      background: rgba($gray, 0.4);
    }
  
    &::-moz-range-thumb {
      @include range-thumb;
    }
  
    input[type=range]::-ms-thumb {
      @include range-thumb;
    }
  }

  & :not(input) {
    position: absolute; 
    left: 0; 
    top: 0;    
    background: rgba($blue, 0.7); 
    height: $size; 
    pointer-events: none;
  }
}