@import '~styles/vars';

.home {
  display: flex;
  flex-direction: column;
  min-height: 100%; 

  & .header {
    & .logo {
      img {
        width: 3.5em;
        vertical-align: middle;
        margin-left: -3px;
      }

      &-box {
        display: inline-block;
        vertical-align: middle;
      }

      &-title {
        font-size: 1.8em;
        font-family: ProximaNova;
        line-height: 1;
        color: #FFFFFF;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-weight: bold;
        
        & > a {
          text-decoration: none;

          &:hover {
            color: inherit;
          }          
        }
      }

      &-description {
        font-size: 0.9em;
        color: #DDD;

        & > a {
          text-decoration: none;

          &:hover {
            color: $blue;
          }          
        }
      }

      &-language {
        margin-top: 0.1em;

        a {
          font-size: 0.9em;
          font-weight: bold;
          color: #777;
          text-transform: uppercase;

          &.active {
            color: $orange;
          }

          &:hover {
            color: $orange;
          }
        }
      }
    }

    & .profile {
      &-auth {
        a {
          width: 5em; 
        }
      }
    }

    & .menu {
      text-align: right;

      &-list {
        margin: 0;
        margin-top: -0.3em;
        text-transform: uppercase;
        font-size: 1.3em;
        font-weight: bold;        
        color: #CCC;        

        & > .list-inline-item {
          &:not(:last-child) {
            margin-right: 1.2em;
          }

          a {
            padding-bottom: 0.3em;
            border-bottom: 1px #333 solid;
          }  

          a.active {
            color: $blue; 
            border-bottom-color: $blue;
          }

          a:hover {
            color: $blue; 
            border-bottom-color: $blue;
          }
        }
      }
    }

    & .menu-bars {
      color: $gray;

      &:hover {
        cursor: pointer;
        color: #FFF;
      }
    }
  }
}