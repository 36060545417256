.colors-modal-window {
  .modal-window-scrollbar {
    background: black; box-shadow: 0px 0px 4px 2px rgba(white, 0.8);
  }

  .colors-list {
    list-style: none;    

    & > li {
      margin-bottom: 0.2em;
      height: 2em;
      cursor: pointer;
      opacity: 0.2;

      &.active:hover {
        opacity: 0.55;
      }

      &:hover {
        opacity: 0.35;
      }

      &.active {
        opacity: 0.7;
      }
    }
  }
}
