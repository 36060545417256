@import '~styles/vars';


.main-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:rgba(0, 0, 0, 0.9);
  z-index: 10000;

  &-top {
    border-bottom: 1px #222 solid;
  }

  &-bottom {
    flex: 1;
    overflow: hidden;
    position: relative;
    height: 20%;
    border-bottom: 1px #222 solid;
  }

  &-close {
    color: $gray;    

    &:hover {
      cursor: pointer;
      color: #FFF;
    }
  }

  &-list {
    position: absolute;
    height: 100%;
    width: 100%;
    list-style: none;    

    & > li {
      flex: 1;
      overflow: hidden;
      position: relative;
      white-space: nowrap;

      &:not(:last-child) {
        a {
          border-bottom: 1px #222 solid;
        }
      }

      & a {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;  
        justify-content: center;     
        padding: 1em;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.6em;

        &:hover {
          background: $blue;
          color: #FFF;
        }

        &.active {
          background: $blue;
          color: #FFF;
        }
      }      
    }
  }  
}