@import '~styles/vars';

.cats {
  height: 100%;
  
  &-list {
    height: 100%;

    & > li {
      & > a {
        color: $gray;

        &:hover {
          color: #FFF;
        }
      }
    }

    &-placeholder {
      font-weight: bold;
      position: absolute; background: rgba(0, 0, 0, 0.6); box-sizing: border-box;
    }
  }
}
