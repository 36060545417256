$blue: #00aaff;
$midnight: #04709f;
$indigo: #6610f2;
$purple: #6f42c1;
$vanilla: #ff9494;
$pink: #e83e8c;
$red: #cc0000;
$orange: #f7a130;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #2aa7d1;
$gray: #888888;
$dark: #333333;
$night: #222222;

$body-bg: black;

$border-radius: 0.1rem;

$input-bg: black;
$input-color: $gray;
$input-border-color: $dark;
$input-focus-border-color: $midnight;
$input-focus-box-shadow: none;
$input-disabled-bg: black;

$card-bg: black;
$card-border-color: $night;

$track-color: #eceff1 !default;
$thumb-color: #607d8b !default;

$thumb-radius: 12px !default;
$thumb-height: 24px !default;
$thumb-width: 24px !default;
$thumb-shadow-size: 4px !default;
$thumb-shadow-blur: 4px !default;
$thumb-shadow-color: rgba(0, 0, 0, .2) !default;
$thumb-border-width: 2px !default;
$thumb-border-color: #eceff1 !default;

$track-width: 100% !default;
$track-height: 1em;
$track-shadow-size: 1px !default;
$track-shadow-blur: 1px !default;
$track-shadow-color: rgba(0, 0, 0, .2) !default;
$track-border-width: 2px !default;
$track-border-color: #cfd8dc !default;

$track-radius: 5px !default;
$contrast: 5% !default;

$custom-control-indicator-bg: $dark;