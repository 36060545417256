@import '~styles/vars';

.privacy-policy {
  font-size: 0.9em;

  & h4 {
    color: lighten($blue, 30%);
  }

  & h5 {
    color: white;
  }
}