@import '~styles/vars';

.modal-window {
  &-overlay {
    position: fixed; 
    z-index: 11000; 
    top: 0; 
    left: 0; 
    background: rgba($night, 0.8); 
    height: 100vh;
    width: 100vw;
    cursor: pointer;
  }

  &-scrollbar {
    position: relative;
    cursor: initial;
    overflow: auto;
    max-height: 90vh; 
    background: black; 
    box-shadow: 0px 0px 4px 2px rgba($blue, 0.6);
  }

  &-close {
    cursor: pointer;
    position: absolute;
    top: 1.3em;
    right: 0.7em;
    opacity: 0.7;
    color: $gray;
    font-size: 1.1em;

    &:hover {
      opacity: 1;
    }
  }

  &-body {     
    box-sizing: border-box;    
    padding: 3em 1.1em 2em 1.1em !important;
  }
}