@import '~styles/vars';

.about {  
  .player-links {
    list-style: inside;
    display: block;
    padding-left: 1em;
    padding-top: 0.5em;

    &  a {
      font-size: 0.95em;
      color: $blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }
 
  .answers-list {
    margin: 0;

    & > li {
      &:not(:last-child) {
        margin-bottom: 1.3em;
      }

      & > *:first-child {
        font-weight: bold;
      }

      & > *:last-child {
        margin-top: 0.2em;
        font-size: 0.9em;
        color: $gray;

        &:before {
          content: "↵";
          display: inline-block;
          transform: scaleX(-1);
          font-weight: bold;
          color: lighten($blue, 30%);
          margin: 0 0.4em 0 0;
        }
      }
    }
  }

  .about-img {
    width: 8em;
  }

  .about-list {
    font-size: 0.92em;
    color: $gray;
    display: inline-block;
    text-align: left;

    & > li { 
      &:not(:last-child) {
        margin-bottom: 0.7em;
      }

      & > a {
        word-break: break-all;

        & > i {
          width: 1.5em;          
        }
      }
    }
  }
}
