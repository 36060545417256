@import '~styles/vars';
@import '~bootstrap/scss/bootstrap-grid';

.music {
  &-card {
    &-buttons {
      height: 1.3em;
      
      @include media-breakpoint-down(xs) {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
      }

      & > *:not(:last-child) {
        display: inline-block;
        margin-right: 0.5em;
      }
  
      & tooltip {
        display: inline-block;
      }

      a {
        position: relative;
        color: $gray;
        font-size: 0.7em;
        cursor: pointer;
        background: $night;
        width: 1.7em;
        height: 1.7em;
        border-radius: 50%;
        display: inline-block;

        & > i {
          position: absolute;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
        }

        &:hover {
          color: $blue;
        }
        
        &.active {
          color: $blue;
        }

        &.active:hover {
          color: $midnight;
        }
      }

      &-colors {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        list-style: none;
        display: flex;
        border-radius: 50%;
        height: 1.3em;
        width: 1.3em;
        flex-direction: column;
        overflow: hidden;
        opacity: 0.5;

        &:hover {
          opacity: 0.8;
        }

        & > * {
          flex-grow: 1;  
        }
      }
    }
  }

  .btn-filter-song {
    position: relative;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }

    & .ellipsis {
      max-width: 92%;
    }

    & .fa-times {
      position: absolute;
      right: 1em;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-playlist {
    & > li:focus, &:focus {
      outline: none;
    }
  }
}